import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';

import {
  default as AssignmentDialog,
  AssignmentDialogModes
} from 'pages/Teachers/shared/AssignmentDialog';
import { useDialog } from 'utils/hooks';
import { parseParams } from 'utils/urls';

import AssignmentDialogContext from 'components/AssignmentDialogContext';
import UsageContext from 'components/UsageContext';

const AssignmentCreateDialogContext = React.createContext({
  defaultMode: AssignmentDialogModes.CREATE,
  isAssignmentCreateDialogOpened: false,
  setIsAssignmentCreateDialogOpened: () => {}
});

export const AssignmentCreateDialogContextProvider = ({ children }) => {
  const { openDialog, closeDialog, isOpened } = useDialog();

  const history = useHistory();

  const searchParams = parseParams(history.location.search);
  const bulkUploadId = _.get(searchParams, 'bulkUploadId');

  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [assignmentTemplateId, setAssignmentTemplateId] = useState(null);
  const [assignmentCreateDialogMode, setAssignmentCreateDialogMode] = useState(
    AssignmentDialogModes.CREATE
  );

  const { openUsageDialog } = useContext(UsageContext);
  const { isAssignmentDialogOpened, setIsAssignmentDialogOpened } = useContext(
    AssignmentDialogContext
  );

  useEffect(() => {
    if (isAssignmentDialogOpened && isOpened) {
      setIsAssignmentDialogOpened(false);
    }
  }, [isOpened, isAssignmentDialogOpened, setIsAssignmentDialogOpened]);

  const open = ({
    mode = AssignmentDialogModes.CREATE,
    selectedAssignment = null,
    assignmentTemplateId = null
  }) => {
    openUsageDialog();

    setAssignmentCreateDialogMode(mode);
    setSelectedAssignment(selectedAssignment);
    setAssignmentTemplateId(assignmentTemplateId);

    openDialog();
  };

  const close = () => {
    setSelectedAssignment(null);
    setAssignmentTemplateId(null);
    closeDialog();
  };

  return (
    <AssignmentCreateDialogContext.Provider
      value={{
        open,
        isOpened,
        closeDialog
      }}
    >
      {children}

      {isOpened && (
        <AssignmentDialog
          defaultMode={assignmentCreateDialogMode}
          assignmentTemplateId={assignmentTemplateId}
          defaultTrackerId={_.get(selectedAssignment, 'tracker_id')}
          bulkUploadId={bulkUploadId}
          onClose={close}
        />
      )}
    </AssignmentCreateDialogContext.Provider>
  );
};

export default AssignmentCreateDialogContext;
