import * as Sentry from '@sentry/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SvgIcon from '@material-ui/core/SvgIcon';
import cx from 'classnames';
import { ReactComponent as AddCommentIcon } from 'images/add-comment.svg';
import { ReactComponent as DefaultAddCommentIcon } from 'images/default-add-comment.svg';
import { ReactComponent as HideCommentsIcon } from 'images/hide-comments.svg';
import _ from 'lodash';
import moment from 'moment';
import { taskStatusUpdate, uploadAssignmentFeedback } from 'sdk';

import StatusBoxIcon from 'pages/Teachers/shared/StatusBoxIcon';
import StickerAddingTool from 'pages/Teachers/shared/StickerAddingTool';
import { colors } from 'theme/palette';
import { uploadImage } from 'utils/files';
import { notifyErrors } from 'utils/notifications';
import { getObjectiveValueLabel } from 'utils/trackers';

import AssignmentTaskValueTypeIcon from 'components/AssignmentTaskValueTypeIcon';
import PaginatedStudentWorkTasksButtons from 'components/PaginatedStudentWorkTasksButtons';
import StudentWorkCardComments from 'components/StudentWorkCardComments';
import StudentWorkTasksButtons from 'components/StudentWorkTasksButtons';
import Typography from 'components/Typography';
import WorkCardImage from 'components/WorkCardImage';

import styles from './styles.module.scss';
import { getObjectiveValue } from './utils';

const StudentWorkCard = ({
  student,
  selected,
  tasksData,
  showScores,
  addComment,
  multiSelectMode,
  pulseEffectTriggered,
  buildAssignmentFeedbackLinkObject,
  toggleSelectStudentWorkItem,
  activeTasksIds,
  visibleTaskIds,
  storeActiveTaskId,
  removeActiveTaskId,
  setSelectedWorksSelectedChildren,
  refetchStudentWork,
  stopTouchScroll = () => {}
}) => {
  const [image, setImage] = useState();
  const [taskIndex, setTaskIndex] = useState(0);
  const [workIndex, setWorkIndex] = useState(0);
  const [showComments, setShowComments] = useState(false);
  const [previousTaskIndex, setPreviousTaskIndex] = useState(null);
  const [paginatedTasksStart, setPaginatedTasksStart] = useState(0);
  const [addingSticker, setAddingSticker] = useState(false);

  const history = useHistory();

  const isExemplar = student.is_teacher_demo_student;

  const groupedByDepthobjectives = useMemo(
    () =>
      _.map(student.score_responses, 'objective').filter((objective) =>
        _.isNil(objective.parent)
      ),
    [student.score_responses]
  );

  const visibleObjectives = useMemo(
    () =>
      _.isEmpty(visibleTaskIds)
        ? groupedByDepthobjectives
        : groupedByDepthobjectives.filter((objective) =>
            visibleTaskIds.includes(objective.id)
          ),
    [groupedByDepthobjectives, visibleTaskIds]
  );

  const indexedResponses = useMemo(
    () => _.keyBy(student.score_responses, 'objective.id'),
    [student.score_responses]
  );

  const tasksWithInitialSetUp = useMemo(
    () =>
      _.map(tasksData, (task, index) => {
        const objective = visibleObjectives[index];

        if (_.isNil(objective)) return task;

        return {
          ...task,
          index: index,
          objective: {
            ...objective,
            value: getObjectiveValue({
              responses: indexedResponses,
              objective: objective
            })
          }
        };
      }),
    [tasksData, visibleObjectives, indexedResponses]
  );

  const [tasks, setTasks] = useState(tasksWithInitialSetUp);
  const activeTask = _.get(tasks, taskIndex, {});

  const activeWork = useMemo(() => {
    if (
      !_.isEmpty(activeTask.children) &&
      workIndex < activeTask.children.length
    ) {
      return activeTask.children[workIndex];
    }

    return activeTask;
  }, [activeTask, workIndex]);

  const bulkStickerCreateInProgress = _.get(
    activeTask,
    'children[0].isLoading'
  );

  useEffect(() => {
    if (!_.isNil(bulkStickerCreateInProgress)) {
      setWorkIndex(0);
    }
  }, [bulkStickerCreateInProgress]);

  useEffect(() => {
    setSelectedWorksSelectedChildren((prev) => {
      prev[activeTask.tracker_score_response_id] = workIndex;

      return prev;
    });
  }, [
    workIndex,
    activeTask.tracker_score_response_id,
    setSelectedWorksSelectedChildren
  ]);

  const studentWorkData = useMemo(
    () =>
      _.find(student.score_responses, {
        id: activeTask.tracker_score_response_id
      }),
    [activeTask.tracker_score_response_id, student.score_responses]
  );

  const comments = useMemo(
    () => _.get(studentWorkData, 'comments', []),
    [studentWorkData]
  );

  useEffect(() => {
    if (!_.isEmpty(comments)) {
      setShowComments(true);
    }
  }, [comments]);

  useEffect(() => {
    setTasks(tasksWithInitialSetUp);
  }, [tasksWithInitialSetUp]);

  useEffect(() => {
    const taskToRemove = _.get(tasks, previousTaskIndex, {});

    const prevActiveTaskId = taskToRemove.tracker_score_response_id;

    if (
      activeTask.tracker_score_response_id !== prevActiveTaskId ||
      activeTask.id !== taskToRemove.id
    ) {
      if (!activeTasksIds.includes(activeTask.tracker_score_response_id)) {
        storeActiveTaskId(activeTask.tracker_score_response_id);
        removeActiveTaskId(taskToRemove.tracker_score_response_id);
      }
    }
  }, [
    activeTask,
    storeActiveTaskId,
    removeActiveTaskId,
    previousTaskIndex,
    tasks,
    activeTasksIds
  ]);

  const tasksButtonsElements = tasksData?.map((task, index) => ({
    label:
      _.findIndex(groupedByDepthobjectives, {
        id: task.tracker_instance_objective_id
      }) + 1,
    key: task.id,
    value: index,
    isActive: index === taskIndex
  }));

  const handleStatusChange = useCallback(
    async (status) => {
      const { success, errors } = await taskStatusUpdate({
        trackerScoreResponseId: activeTask.tracker_score_response_id,
        data: { status }
      });

      if (success) {
        refetchStudentWork();
      }

      if (!_.isEmpty(errors)) {
        notifyErrors(errors);
      }
    },
    [activeTask, refetchStudentWork]
  );

  const handleRightArrowClick = useCallback(() => {
    if (paginatedTasksStart + 2 === taskIndex) {
      setPaginatedTasksStart((prevTaskIndex) => prevTaskIndex + 1);
    }

    setPreviousTaskIndex(taskIndex);

    setTaskIndex((taskIndex) => taskIndex + 1);
    setShowComments(false);
    setWorkIndex(0);
  }, [paginatedTasksStart, taskIndex]);

  const handleLeftArrowClick = useCallback(() => {
    if (paginatedTasksStart === taskIndex) {
      setPaginatedTasksStart((prevTaskIndex) => prevTaskIndex - 1);
    }

    setPreviousTaskIndex(taskIndex);

    setTaskIndex((taskIndex) => taskIndex - 1);
    setShowComments(false);
    setWorkIndex(0);
  }, [paginatedTasksStart, taskIndex]);

  const handleCommentAdd = useCallback(
    ({ event }) =>
      addComment({
        studentId: student.id,
        taskId: activeTask.tracker_score_response_id,
        data: { text: event.value }
      }),
    [addComment, student.id, activeTask.tracker_score_response_id]
  );

  const handleStickerAdd = useCallback(
    async (blob) => {
      // This is optimistic rendering
      setAddingSticker(true);
      try {
        const url = window.URL.createObjectURL(blob);
        setImage(url);
      } catch (error) {
        console.error(error);
        Sentry.captureException(error, {
          extra: {
            blob,
            hasUrl: !!URL,
            hasHrlCreateObjectURL: URL && !!URL.createObjectURL
          }
        });
      }

      const feedbackFileId = await uploadImage({
        blob,
        name: 'feedback'
      });

      const { data, success, errors } = await uploadAssignmentFeedback({
        data: {
          feedback_file_id: feedbackFileId,
          original_work: activeWork.original_id || activeWork.id
        }
      });

      if (success) {
        setWorkIndex(0);
        setTasks((prev) =>
          prev.map((task, index) => {
            if (index === taskIndex)
              return {
                ...activeTask,
                children: [data, ...activeTask.children]
              };
            else return task;
          })
        );
        setImage();
        refetchStudentWork();
      } else {
        notifyErrors(errors);
      }

      setAddingSticker(false);
    },
    [activeWork, activeTask, taskIndex, refetchStudentWork]
  );

  const onMultiselectClick = useCallback(
    (event) => {
      event.stopPropagation();
      toggleSelectStudentWorkItem({
        studentId: student.id,
        trackerScoreResponseId: activeTask.tracker_score_response_id,
        selected: !selected
      });
    },
    [
      selected,
      student.id,
      activeTask.tracker_score_response_id,
      toggleSelectStudentWorkItem
    ]
  );

  const cardTitle = isExemplar
    ? `Exemplar - ${student.preferred_name}`
    : student.name;

  const showPaginatedTaskButtons = tasks.length > 5;
  const showAssignmentTasksButtons = tasks.length <= 5;

  const activeWorkOptions = useMemo(
    () =>
      _.isNil(activeTask.children)
        ? [activeTask]
        : [...activeTask.children, activeTask],
    [activeTask]
  );

  const showActiveWorksDropdown = activeWorkOptions?.length > 1;

  const assignmentFeedbackLink = useMemo(
    () =>
      buildAssignmentFeedbackLinkObject({
        student: student.id,
        work: activeWork?.id,
        task: activeTask.tracker_score_response_id
      }),
    [
      buildAssignmentFeedbackLinkObject,
      student.id,
      activeTask.tracker_score_response_id,
      activeWork
    ]
  );

  const showLinearProgress =
    addingSticker || _.get(activeTask, 'children[0].isLoading');

  return (
    <div
      className={cx(styles.container, {
        [styles.cardsAnimated]: selected && pulseEffectTriggered,
        [styles.selected]: selected,
        [styles.exemplarCard]: student.is_teacher_demo_student
      })}
    >
      {multiSelectMode && (
        <div
          className={styles.multiSelectCardOverlay}
          onClick={onMultiselectClick}
        />
      )}

      <WorkCardImage
        isEmptyWork={_.get(activeWork, 'emptyWork', false)}
        onClick={() => history.replace(assignmentFeedbackLink)}
        activeWork={activeWork}
        activeWorkIndex={workIndex}
        activeTaskIndex={taskIndex}
        assignmentTasks={tasks}
        activeTaskWork={activeWorkOptions}
        onLeftArrowClick={handleLeftArrowClick}
        onTopArrowClick={() => setWorkIndex((prevIndex) => prevIndex - 1)}
        onBottomArrowClick={() => setWorkIndex((prevIndex) => prevIndex + 1)}
        onRightArrowClick={handleRightArrowClick}
        multiSelectMode={multiSelectMode}
        selected={selected}
      />

      {showLinearProgress && <LinearProgress />}

      <div className={styles.actionRow}>
        <Grid container alignItems="center" className={styles.icons}>
          <Grid item>
            <StatusBoxIcon
              iconButtonClasses={{
                root: styles.iconRoot,
                label: styles.iconLabel
              }}
              disabled={_.get(activeWork, 'emptyWork', false)}
              currentStatus={activeTask.status}
              changeStatus={handleStatusChange}
            />
          </Grid>

          {showComments ? (
            <Grid item>
              <SvgIcon
                component={HideCommentsIcon}
                className={styles.messageIcon}
                onClick={() => setShowComments(false)}
              />
            </Grid>
          ) : (
            <Grid item>
              <SvgIcon
                component={
                  !_.isEmpty(comments) ? AddCommentIcon : DefaultAddCommentIcon
                }
                className={styles.messageIcon}
                onClick={() => setShowComments(true)}
              />
            </Grid>
          )}

          <Grid item className={styles.messageIcon}>
            <StickerAddingTool
              stickerIconFill={colors.grey4}
              disabled={Boolean(activeWork?.emptyWork)}
              imageSrc={image || activeWork.work_url}
              onStickerAdd={handleStickerAdd}
              iconButtonClasses={{ root: styles.iconRoot }}
            />
          </Grid>
        </Grid>

        <div className={styles.taskButtons}>
          {showPaginatedTaskButtons && (
            <PaginatedStudentWorkTasksButtons
              elements={tasksButtonsElements}
              onChange={(element) => {
                setPreviousTaskIndex(taskIndex);
                setTaskIndex(element.value);
                setWorkIndex(0);
                setShowComments(false);
              }}
              onLeftArrowClick={handleLeftArrowClick}
              onRightArrowClick={handleRightArrowClick}
              firstElementIndex={paginatedTasksStart}
              elementsCount={tasks.length}
            />
          )}
          {showAssignmentTasksButtons && (
            <StudentWorkTasksButtons
              elements={tasksButtonsElements}
              onChange={(element) => {
                setPreviousTaskIndex(taskIndex);

                setTaskIndex(element.value);
                setWorkIndex(0);
              }}
            />
          )}
        </div>
        <div className={styles.selectWork}>
          {showActiveWorksDropdown && (
            <FormControl>
              <Select
                className={styles.selectField}
                classes={{ root: styles.selectFieldRoot }}
                value={workIndex}
                onMouseDown={stopTouchScroll}
                onChange={(e, element) => {
                  setWorkIndex(element.props.value);
                }}
              >
                {activeWorkOptions.map((workItem, index) => {
                  if (workItem.is_feedback) {
                    return (
                      <MenuItem key={index} value={index}>
                        Feedback
                      </MenuItem>
                    );
                  }

                  if (workItem.is_revision) {
                    return (
                      <MenuItem key={index} value={index}>
                        Revision
                      </MenuItem>
                    );
                  }

                  return (
                    <MenuItem key={index} value={index}>
                      Original
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </div>
      </div>

      <Typography variant="B-Text-1" className={styles.name}>
        {cardTitle}
      </Typography>

      {showScores && (
        <>
          <Divider />
          <div className={styles.taskInfoContainer}>
            <Grid container className={styles.spaceBetween}>
              <Grid item>
                <Typography variant="S-TEXT-1" color={colors.grey1}>
                  {activeTask.index + 1}. {activeTask.objective.name}
                </Typography>
              </Grid>

              {!_.isNull(activeTask.objective.value) && (
                <Grid item>
                  <div className={styles.taskScore}>
                    <AssignmentTaskValueTypeIcon
                      task={activeTask.objective}
                      color={colors.blue4}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
            {_.get(activeTask.objective, 'children', []).map(
              (criteria, index) => (
                <div key={index} className={styles.criteria}>
                  <Typography variant="B-Text-3" color={colors.grey2}>
                    {criteria.name}
                  </Typography>
                  <Typography
                    variant="S-TEXT-2"
                    color={colors.blue4}
                    className={styles.criteriaStatus}
                  >
                    {getObjectiveValueLabel(
                      criteria,
                      indexedResponses[criteria.id].value
                    )}
                  </Typography>
                </div>
              )
            )}
          </div>
        </>
      )}
      {showComments && (
        <div className={styles.comments}>
          <Divider />
          <StudentWorkCardComments
            comments={_.orderBy(
              [
                ...comments,
                ..._.flatten(comments.map((x) => _.get(x, 'replies', [])))
              ],
              (x) => moment(x.created_at)
            )}
            onSubmit={(event) => handleCommentAdd({ event })}
          />
        </div>
      )}
    </div>
  );
};

export default StudentWorkCard;
