import _ from 'lodash';
import moment from 'moment';

import { flattenObjectives } from 'utils/trackers';

export const splitAssignmentsToMonths = ({ assignments }) => {
  const sortedAssignments = _.orderBy(assignments, 'due_at', 'desc').map(
    (assignment) => ({
      ...assignment,
      image: _.get(assignment, 'tasks.[0].work[0].work_url'),
      isEmptyWork: _.isEmpty(_.get(assignment, 'tasks.[0].work[0]')),
      month: moment(assignment.due_at).format('MMMM')
    })
  );

  return _.groupBy(sortedAssignments, 'month');
};

export const buildFormDataForCreate = ({ values }) => {
  const tasksWithStandardIds = _.map(values.tasks, (task) => ({
    ...task,
    standard_ids: task.standards.map((standard) => standard.id)
  }));

  const tasksWithOmittedStandards = _.map(tasksWithStandardIds, (task) =>
    _.omit(task, 'standards')
  ); // We do not need to pass the "standards" key to the BE

  const filteredObjectives = _.filter(
    flattenObjectives(tasksWithOmittedStandards),
    (objective) => !_.isEmpty(objective.name)
  );

  // Set `order` for each objective to be the consecutive index, since that's how we want them ordered.
  const objectives = _.map(filteredObjectives, (objective, index) => ({
    ...objective,
    order: index
  }));

  const formData = new FormData();
  formData.append('name', values.assignmentName);
  formData.append('description', values.description);

  _.forEach(values.attachments, (attachment) =>
    formData.append('attachment_file_ids', attachment.id)
  );

  _.forEach(objectives, (obj) =>
    formData.append('objectives', JSON.stringify(obj))
  );

  return formData;
};

export const buildFormDataForUpdate = ({ values }) => {
  const tasksWithStandardIds = _.map(values.tasks, (task) => ({
    ...task,
    standard_ids: task.standards.map((standard) => standard.id)
  }));

  const tasksWithOmittedStandards = _.map(tasksWithStandardIds, (task) =>
    _.omit(task, 'standards')
  ); // We do not need to pass the "standards" key to the BE

  const filteredObjectives = _.map(tasksWithOmittedStandards, (task) => {
    task.children = _.filter(
      task.children,
      (criteria) => !_.isEmpty(criteria.name)
    );
    return task;
  });

  let lastOrder = -1;
  const updatedTasks = _.map(filteredObjectives, (parent, index) => {
    const order = lastOrder + 1;
    const children = _.map(parent.children, (child, childIndex) => {
      const childOrder = order + childIndex + 1;
      return { ...child, order: childOrder };
    });

    lastOrder = order + children.length;

    return { ...parent, order, children };
  });

  const formData = new FormData();
  formData.append('name', values.assignmentName);
  formData.append('description', values.description);

  _.forEach(values.attachments, (attachment) =>
    formData.append('attachment_file_ids', attachment.id)
  );

  _.forEach(updatedTasks, (obj) =>
    formData.append('objectives', JSON.stringify(obj))
  );

  return formData;
};
