import React, { useContext, useState } from 'react';

import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import _ from 'lodash';

import { colors } from 'theme/palette';

import AssignmentFromTemplateForm from './components/AssignmentFromTemplateForm';
import AssignmentDialogContext from 'components/AssignmentDialogContext';
import Dialog from 'components/Dialog';
import Typography from 'components/Typography';

import AssignIcon from './assets/assign.svg';
import CreateIcon from './assets/create.svg';
import ShareIcon from './assets/share.svg';
import { AssignAssignment, AssignmentForm, StepperIcon } from './components';
import styles from './styles.module.scss';

export const AssignmentDialogModes = {
  COPY: 'copy',
  UPDATE: 'update',
  CREATE: 'create',
  CREATE_FROM_TEMPLATE: 'create from template'
};

const Connector = withStyles({
  line: {
    borderTopWidth: 4
  },
  active: {
    '& $line': { borderColor: 'var(--pink-1)' }
  },
  completed: {
    '& $line': { borderColor: 'var(--pink-4)' }
  }
})(StepConnector);

const AssignmentDialog = ({
  onClose,
  defaultTrackerId,
  assignmentTemplateId,
  defaultStep = 0,
  defaultMode = AssignmentDialogModes.CREATE,
  isAssigned = false,
  bulkUploadId = null
}) => {
  const [activeStep, setActiveStep] = useState(defaultStep);
  const [trackerId, setTrackerId] = useState(defaultTrackerId);

  const { setIsAssignmentDialogOpened, assignAssignmentSuccessAction } =
    useContext(AssignmentDialogContext);

  const [mode, setMode] = useState(defaultMode);
  const [selectedSections, setSelectedSections] = useState(null);

  const createFromTemplate =
    mode === AssignmentDialogModes.CREATE_FROM_TEMPLATE;

  const goToNextStep = () => setActiveStep(activeStep + 1);

  const onCreateSuccess = ({ id }) => {
    setTrackerId(id);

    goToNextStep();
  };

  const stepper = (
    <Stepper
      className={cx(styles.stepper, {
        [styles.condensed]: !_.isNil(bulkUploadId)
      })}
      activeStep={activeStep}
      connector={<Connector />}
    >
      <Step>
        <StepLabel
          StepIconComponent={(props) => (
            <StepperIcon src={CreateIcon} {...props} />
          )}
        >
          <Typography
            className={styles.stepperText}
            color={activeStep > 0 ? colors.pink4 : colors.grey3}
            variant="B-Text-3"
          >
            {createFromTemplate
              ? _.startCase(AssignmentDialogModes.CREATE)
              : _.startCase(mode)}
          </Typography>
        </StepLabel>
      </Step>
      <Step>
        <StepLabel
          StepIconComponent={(props) => (
            <StepperIcon src={AssignIcon} {...props} />
          )}
        >
          <Typography
            className={styles.stepperText}
            color={activeStep > 1 ? colors.pink4 : colors.grey3}
            variant="B-Text-3"
          >
            Assign
          </Typography>
        </StepLabel>
      </Step>
      {_.isNil(bulkUploadId) && (
        <Step>
          <StepLabel
            StepIconComponent={(props) => (
              <StepperIcon src={ShareIcon} {...props} />
            )}
          >
            <Typography
              className={styles.stepperText}
              color={colors.grey3}
              variant="B-Text-3"
            >
              Capture
            </Typography>
          </StepLabel>
        </Step>
      )}
    </Stepper>
  );

  return (
    <Dialog
      open
      alignTop
      fullWidth
      maxWidth="md"
      onClose={onClose}
      disableBackdropClick={true}
    >
      {activeStep === 0 && !createFromTemplate && (
        <AssignmentForm
          mode={mode}
          stepper={stepper}
          trackerId={trackerId}
          isAssigned={isAssigned}
          onClose={onClose}
          onCreateSuccess={onCreateSuccess}
          onUpdateSuccess={goToNextStep}
        />
      )}
      {activeStep === 0 && createFromTemplate && (
        <AssignmentFromTemplateForm
          stepper={stepper}
          assignmentTemplateId={assignmentTemplateId}
          onCreateSuccess={onCreateSuccess}
        />
      )}
      {(activeStep === 1 || activeStep === 2) && trackerId && (
        <AssignAssignment
          stepper={stepper}
          trackerId={trackerId}
          onDialogClose={onClose}
          storeSelectedSections={setSelectedSections}
          selectedSections={selectedSections}
          prevStep={() => {
            setMode(AssignmentDialogModes.UPDATE);
            setActiveStep(activeStep - 1);
          }}
          nextStep={() => setActiveStep(activeStep + 1)}
          onResultsClick={() => setIsAssignmentDialogOpened(false)}
          bulkUploadId={bulkUploadId}
          onAssignmentAssignSuccess={assignAssignmentSuccessAction}
        />
      )}
    </Dialog>
  );
};

export default AssignmentDialog;
