import React from 'react';

import _ from 'lodash';

import { useDialog } from 'utils/hooks';

import StandardButton from 'components/StandardButton';
import StandardDetailDialog from 'components/StandardDetailDialog';

import styles from './styles.module.scss';

const StandardItem = ({ standard, onClick }) => {
  const {
    isOpened: isStandardDetailDialogOpened,
    openDialog: openStandardDetailDialog,
    closeDialog: closeStandardDetailDialog
  } = useDialog();

  const shortDescription = _.get(standard, 'short_description', '');

  return (
    <>
      <StandardButton
        className={styles.fullHeight}
        label={
          <div>
            <strong>{standard.code}</strong>
            {!_.isEmpty(shortDescription) && ` - ${shortDescription}`}
          </div>
        }
        onClick={onClick || openStandardDetailDialog}
      />

      {isStandardDetailDialogOpened && (
        <StandardDetailDialog
          standardId={standard.id}
          onClose={closeStandardDetailDialog}
        />
      )}
    </>
  );
};

export default StandardItem;
